import React, { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import useSWR from 'swr';

import { DataProvider } from '../../dataSource/DataContext/DataContext';
import { initI18n } from '../../dataSource/LocaleContext/i18n';

import { hideLoader } from '../../utils/ui';

export default function Layout() {
  const haptic = window.Telegram.WebApp.HapticFeedback;
  const [isI18nLoading, setIsI18nLoading] = useState(true);

  useEffect(() => {
    initI18n()
      .catch(console.error)
      .finally(() => setIsI18nLoading(false));
  }, []);

  useEffect(() => {
    if (!isI18nLoading ) {
      // We are not hiding the loader on purpose, because change of loadingI18n to
      // false will also lead to rendering the Outlet component, which may be the reason
      // of layout shifts (due to loading images, for example).
      const timeoutId = setTimeout(hideLoader, 100);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isI18nLoading,]);

  return (
    <>
      <ScrollRestoration/>
      <DataProvider isSigned={true} signaturesCount={9020672}>
        {!isI18nLoading && <Outlet/>}
      </DataProvider>
    </>
  );
}
